<template>
  <b-row>

    <b-col
      cols="12"
      class="d-flex align-items-end"
    >
      <div
        class="d-flex flex-column flex-md-row justify-content-between flex-grow-1"
      >
        <div>
          <div
            v-if="products.length > 1"
            class="d-flex"
            style="column-gap: 5px; row-gap: 5px;"
          >
            <b-button
              v-if="tableProducts.selected.details.length <= 0"
              size="sm"
              variant="outline-success"
              class="text-nowrap min-w-100px"
              @click.prevent="$event => selectProducts(true)"
            >
              Select All
            </b-button>
            <b-button
              v-else-if="tableProducts.selected.details.length > 0"
              size="sm"
              variant="outline-success"
              class="text-nowrap min-w-100px"
              @click.prevent="$event => selectProducts(false)"
            >
              Unselect All
            </b-button>
            <b-button
              v-if="tableProducts.selected.details.length > 0"
              size="sm"
              variant="outline-danger"
              class="text-nowrap"
              @click.prevent="$event => removeProducts()"
            >
              Remove Selected
            </b-button>
          </div>
        </div>
        <div
          class="d-flex align-self-end pb-2 order-1 order-md-2"
          style="column-gap: 5px; row-gap: 5px;"
        >
          <div>
            <b-input-group
              size="sm"
            >
              <b-form-input
                v-model="additional"
                class="text-right min-w-100px max-w-125px"
                placeholder="0"
                type="number"
                min="1"
                max="25"
                step="1"
                size="sm"
              />
              <b-input-group-append>
                <b-button
                  size="sm"
                  variant="outline-dark"
                  @click.prevent="$event => addProduct(additional)"
                >
                  +Rows
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div
            class="d-flex"
            style="column-gap: 5px; row-gap: 5px;"
          >
            <b-button
              size="sm"
              variant="outline-dark"
              class="text-nowrap"
              @click.prevent="$event => addProduct(5)"
            >
              +5 Rows
            </b-button>
            <b-button
              size="sm"
              variant="outline-dark"
              class="text-nowrap"
              @click.prevent="$event => addProduct(10)"
            >
              +10 Rows
            </b-button>
          </div>
        </div>
      </div>
    </b-col>

    <b-col
      cols="12"
    >

      <form
        role="form"
        novalidate
        @submit.prevent
      >
        <b-table
          ref="tableProducts"
          small
          hover
          striped
          bordered
          responsive
          show-empty
          class="mb-0 px-0 min-h-300px"
          sticky-header="calc(100vh - 21.6rem)"
          :items="products"
          :busy="tableProducts.busy"
          :filter="tableProducts.filter"
          :fields="tableProducts.fields"
          :stacked="tableProducts.stacked"
          :filter-included-fields="tableProducts.filterOn"
          @row-contextmenu="tableRowRightClick"
        >

          <template #head(selected)>
            <b-form-checkbox
              v-model="tableProducts.selected.allSelected"
              :indeterminate="(tableProducts.selected.indeterminate)"
              :disabled="(tableProducts.busy || products.length <= 1)"
              size="lg"
              tabindex="-1"
              @change="selectProducts"
            />
          </template>

          <template #cell(index)="{ index }">
            <div
              class="text-md-nowrap d-flex justify-content-center align-items-centermin-h-35px"
              v-text="tableRowNumber(tableProducts, index)"
            />
          </template>

          <template #cell(selected)="{ index }">
            <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
              <b-form-checkbox
                v-model="tableProducts.selected.details"
                :disabled="(tableProducts.busy || products.length <= 1)"
                :value="index"
                size="lg"
                tabindex="-1"
              />
            </div>
          </template>

          <template #cell(action)="{ item, index }">
            <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
              <b-button
                :disabled="(tableProducts.busy || products.length <= 1)"
                variant="outline-danger"
                class="border-0"
                tabindex="-1"
                @click.prevent="() => removeProduct(item, index, false)"
              >
                <b-icon
                  icon="trash"
                  font-scale="1"
                />
              </b-button>
            </div>
          </template>

          <template #cell(product.category_name)="{ value }">
            <div class="text-md-nowrap d-flex align-items-center min-h-35px px-1">
              <strong
                class="font-weight-bolder"
                style="font-size: 1rem;"
                v-text="value"
              />
            </div>
          </template>

          <template #cell(product_name)="{ item, index }">
            <div class="text-md-nowrap">
              <Product
                :item="item"
                :index="index"
                :options="filterProductOptions(item.product)"
                @selected="selectProduct"
              />
            </div>
          </template>

          <template #cell(quantity)="{ item, index }">
            <div class="text-md-nowrap">
              <Quantity
                :item="item"
                :index="index"
                @enter="focusOnNewProduct"
                @cleared="focusOnProductSelection"
                @moveUp="moveUpProductQuantity"
                @moveDown="moveDownProductQuantity"
              />
            </div>
          </template>

          <!-- <template #cell(action)="{ item, index }">
        <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center" />
      </template> -->

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </form>
    </b-col>
  </b-row>
</template>
<script>
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import Product from '@/views/user/requests/products/Product'
import Quantity from '@/views/user/requests/products/Quantity'

export default {
  name: 'Products',
  components: {
    Product,
    Quantity
  },
  mixins: [
    MISC,
    FORMATTER
  ],
  props: {
    details: {
      type: Array,
      default: () => [],
      required: true
    },
    options: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data () {
    return {
      additional: 1,
      products: this.details,
      selected: {
        product: null,
        products: []
      },
      tableProducts: {
        selected: {
          details: [],
          allSelected: false,
          indeterminate: false
        },
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center min-w-25px' },
          { mobile: 1, key: 'selected', class: 'text-center min-w-50px' },
          { mobile: 2, key: 'product.category_name', label: 'Category', class: 'col-12 col-md-3' },
          { mobile: 3, key: 'product_name', class: 'col-12 col-md-6' },
          { mobile: 4, key: 'quantity', class: ['text-center', { 'col-12 col-md-2': !this.isMobile() }] },
          { mobile: 5, key: 'action', class: 'text-center min-w-50px' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {

    'tableProducts.selected.details' (newValues) {
      if (newValues.length === 0) {
        this.tableProducts.selected.indeterminate = false
        this.tableProducts.selected.allSelected = false
      } else if (newValues.length === this.products.length) {
        this.tableProducts.selected.indeterminate = false
        this.tableProducts.selected.allSelected = true
      } else {
        this.tableProducts.selected.indeterminate = true
        this.tableProducts.selected.allSelected = false
      }
    }
  },

  methods: {

    tableRowRightClick (_product, _productLocation, event) {
      event.preventDefault()
    },

    filterProductOptions (sProduct) {
      const products = this.products.filter(
        ({ product }) => product?.product_code
      ).map(
        ({ product }) => product?.product_code
      )
      return this.options.filter(
        oProduct => (
          (oProduct.product_code === sProduct?.product_code) || (
            !products.includes(oProduct.product_code)
          )
        )
      )
    },

    focusOnProductQuantity (_, quantityLocation) {
      const quantity = document.getElementById(`details.${quantityLocation}.quantity`)
      if (quantity) {
        quantity.focus()
      }
    },

    moveUpProductQuantity (_, quantityLocation) {
      this.focusOnProductQuantity(_, quantityLocation - 1)
    },

    moveDownProductQuantity (_, quantityLocation) {
      this.focusOnProductQuantity(_, quantityLocation + 1)
    },

    focusOnProductSelection (_, productLocation) {
      const product = document.getElementById(`details.${productLocation}.product`)
      if (product) {
        product.focus()
      }
    },

    focusOnNewProduct (_, productLocation) {
      const nextLocation = Number(productLocation) + 1

      if (this.products.length === nextLocation) {
        this.addProduct(1)
      }

      setTimeout(() => {
        const hasNext = document.getElementById(`details.${nextLocation}.product`)
        if (hasNext) {
          hasNext.focus()
        }
      }, 300)
    },

    addProduct (count = 5) {
      if (count > 25) {
        this.additional = 25
        return null
      }

      this.products.push(
        ...Array.from({ length: Number(count) }).map(
          () => ({
            product: null,
            quantity: null
          })
        )
      )

      this.additional = 1

      // this.updated()
    },

    removeProduct (_, productLocation, useFocus = false) {
      const validatedProducts = this.products.filter(
        (_, location) => Number(productLocation) !== Number(location)
      )

      if (validatedProducts.length <= 0) {
        return this.swalInvalid('<h6>Request must have atleast 1 product</h6>', 'Removal Canceled')
      }

      if (validatedProducts.length <= 1) {
        this.tableProducts.selected.details = []
      }

      this.products = validatedProducts

      if (useFocus) {
        this.focusOnProductSelection(_, (Number(productLocation) - 1))
      }

      this.$vnode.context.$refs.formProducts.reset()
      // this.updated()
    },

    removeProducts () {
      const validatedProducts = this.products.filter(
        (_, location) => !this.tableProducts.selected.details.includes(location)
      )

      if (validatedProducts.length <= 0) {
        return this.swalInvalid('<h6>Request must have atleast 1 product</h6>', 'Removal Canceled')
      }

      this.products = validatedProducts

      this.tableProducts.selected.details = []
    },

    selectProduct (_selectedProduct, _productLocation) {
      // this.updated()
    },

    selectProducts (selectAll) {
      this.tableProducts.selected.details = selectAll ? (
        this.products.map((_, location) => location)
      ) : []
    },

    updated () {
      this.$emit('updated', this.products)
    },

    getFinalProducts () {
      return this.products.map(
        ({ product, quantity }) => ({
          id: product?.id || null,
          category_id: product?.category_id || null,
          category_code: product?.category_code || null,
          category_name: product?.category_name || null,
          group_code: product?.group_code || null,
          group_name: product?.group_name || null,
          product_code: product?.product_code || null,
          product_name: product?.product_name || null,
          quantity: quantity || null
        })
      )
    },

    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.formProducts.validate().then(
          validProducts => {
            if (validProducts) {
              resolve(validProducts)
            } else {
              reject(validProducts)
            }
          }
        )
      })
    }
  }
}
</script>
